<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="grampanchayatlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="mba1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Gram Panchayat</h5>
                        </template>
                        <template v-slot:right>
                            <!-- <Button label="Import" icon="pi pi-paperclip" class="p-mr-2"
                                @click="onImportGramPanchayat" /> -->
                            <Button label="Add Gram Panchayat" icon="pi pi-plus" class=" p-mr-2"
                                @click="addGramPanchayatDialogOpen" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash"
                                class="p-button-success" @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="Gram Panchayat Name" headerStyle="width: 28%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mba6 ? data.mba6 : 'N/A' }} <span v-if="data.mba7"
                                    v-tooltip.right="'Constituency Number'">({{ data.mba7 }})</span></div>
                        </template>
                    </Column>
                    <Column header="Zilla Parishad" headerStyle="width: 29%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mba3 ? data.mba3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Panchayat Samiti" headerStyle="width: 29%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mba5 ? data.mba5 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editGramPanchayatDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add Gram Panchayat dialog start here -->
    <Dialog v-model:visible="addGramPanchayatStatus" :style="{ width: '800px' }" header="Add Gram Panchayat"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishad">
                        Zilla Parishad
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="zillaparishad" v-model="zillaparishad" :options="zillaparishadList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.zillaparishad.$error">{{
                            v$.zillaparishad.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="panchayatsamiti">
                        Panchayat Samiti
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="panchayatsamiti" v-model="panchayatsamiti" :options="panchayatsamitiList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.panchayatsamiti.$error">{{
                            v$.panchayatsamiti.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="constituencynumber">
                        Constituency Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="constituencynumber" class="p-text-capitalize" v-model.trim="constituencynumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !constituencynumber }" />
                    <small class="p-invalid p-error" v-if="v$.constituencynumber.$error">{{
                            v$.constituencynumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="grampanchayatname">
                        Gram Panchayat Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="grampanchayatname" class="p-text-capitalize" v-model.trim="grampanchayatname"
                        required="true" maxlength="60" autofocus
                        :class="{ 'p-invalid': submitted && !grampanchayatname }" />
                    <small class="p-invalid p-error" v-if="v$.grampanchayatname.$error">{{
                            v$.grampanchayatname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addGramPanchayat" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add Gram Panchayat dialog end here -->
    <!-- edit Gram Panchayat dialog start here -->
    <Dialog v-model:visible="editGramPanchayatStatus" :style="{ width: '800px' }" header="Edit Gram Panchayat"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="zillaparishad">
                        Zilla Parishad
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="zillaparishad" v-model="zillaparishad" :options="zillaparishadList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.zillaparishad.$error">{{
                            v$.zillaparishad.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="panchayatsamiti">
                        Panchayat Samiti
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="panchayatsamiti" v-model="panchayatsamiti" :options="panchayatsamitiList"
                        optionLabel="label" placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.panchayatsamiti.$error">{{
                            v$.panchayatsamiti.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="constituencynumber">
                        Constituency Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="constituencynumber" class="p-text-capitalize" v-model.trim="constituencynumber"
                        required="true" @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': submitted && !constituencynumber }" />
                    <small class="p-invalid p-error" v-if="v$.constituencynumber.$error">{{
                            v$.constituencynumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="grampanchayatname">
                        Gram Panchayat Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="grampanchayatname" class="p-text-capitalize" v-model.trim="grampanchayatname"
                        required="true" maxlength="60" autofocus
                        :class="{ 'p-invalid': submitted && !grampanchayatname }" />
                    <small class="p-invalid p-error" v-if="v$.grampanchayatname.$error">{{
                            v$.grampanchayatname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateGramPanchayat" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit Gram Panchayat dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="zillaparishadname">Zilla Parishad</label>
                    <MultiSelect v-model="zillaparishad_fks" :options="zillaparishadList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="zillaparishadname">Panchayat Samiti</label>
                    <MultiSelect v-model="panchayat_fks" :options="panchayatList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="zillaparishadname">Gram Panchayat</label>
                    <MultiSelect v-model="grampanchayat_fks" :options="grampanchayatList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData( zillaparishad_fks, panchayat_fks, grampanchayat_fks)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportGramPanchayat" :style="{ width: '1100px' }" header="Import Gram Panchayat" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" name="demo[]" url="./upload" accept="text/csv" :maxFileSize="1000000" @upload="onUpload" />
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                        <label class="p-mr-2">
                            Download Sample Sheet:
                        </label>
                        <!-- TODO: Need CSV File URL here -->
                        <router-link href="replace-csv-string" target="_blank" to="#">
                            <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                            </Button>
                        </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_open_enquiries.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.</li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source column's are mandatory.</li>
                        <li>Submitted sheets for importing enquiries till 10 pm will be executed on the same night and the result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
                <Button label="Submit" icon="pi pi-check" class="p-button-text"
                @click="onSubmit" />
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            grampanchayatlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addGramPanchayatStatus: false,
            editGramPanchayatStatus: false,
            zillaparishad: '',
            zillaparishadList: [],
            panchayatsamiti: '',
            panchayatsamitiList: [],
            constituencynumber: '',
            grampanchayatname: '',
            reservationvalue: '',
            reservationList: [],
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            zillaparishad_fks: [],
            panchayat_fks: [],
            panchayatList: [],
            grampanchayat_fks: [],
            grampanchayatList: [],
            showFillFilter: false,
            isImportGramPanchayat: false,
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getgrampanchayatlist();
        this.getReservations();
        this.getzillaparishad();
        this.getpanchayatsamiti();
    },
    validations() {
        return {
            zillaparishad: { required: helpers.withMessage('Please select zilla parishad', required) },
            panchayatsamiti: { required: helpers.withMessage('Please select panchayatsamiti', required) },
            constituencynumber: { required: helpers.withMessage('Please enter constituency number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric) },
            grampanchayatname: { required: helpers.withMessage('Please enter grampanchayat name', required) },
            reservationvalue: { required: helpers.withMessage('Please select reservation', required) },
        };
    },
    methods: {
        onImportGramPanchayat() {
            this.isImportGramPanchayat = true;
        },
        getpanchayatsamiti(ev) {
            this.ApiService.getpanchayatsamiti(ev).then((data) => {
                if (data.status == 200) {
                    this.panchayatsamitiList = data.data;
                } else {
                    this.panchayatsamitiList = '';
                }
            });
        },
        getzillaparishad(ev) {
            this.ApiService.getzillaparishad(ev).then((data) => {
                if (data.status == 200) {
                    this.zillaparishadList = data.data;
                } else {
                    this.zillaparishadList = '';
                }
            });
        },
        getReservations(ev) {
            this.ApiService.getReservations(ev).then((data) => {
                if (data.status == 200) {
                    this.reservationList = data.data;
                } else {
                    this.reservationList = '';
                }
            });
        },
        getgrampanchayatlist(ev) {
            this.loading = true;
            this.ApiService.getgrampanchayatlist(ev).then((data) => {
                if (data.status == 200) {
                    this.grampanchayatlist = data.data.result;
                    this.totalRecords = data.data.count;
                } else {
                    this.grampanchayatlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getgrampanchayatlist({ 
                page_no: event.page,
                zillaparishadfilter: this.event_zillaparishad,
                panchayatfilter: this.event_panchayat,
                grampanchayatfilter: this.event_grampanchayat,
             });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addGramPanchayatDialogOpen() {
            this.addGramPanchayatStatus = true;
            this.submitted = false;
            this.zillaparishad = '';
            this.panchayatsamiti = '';
            this.constituencynumber = '';
            this.grampanchayatname = '';
            this.reservationvalue = '';
        },
        editGramPanchayatDialogOpen(e) {
            this.editGramPanchayatStatus = true;
            this.submitted = false;
            this.row_id = e.mba1;
            let mba2 = this.zillaparishadList.filter(function (item) {
                return item.value == e.mba2;
            });
            if (mba2.length > 0) {
                this.zillaparishad = mba2[0];
            }
            let mba4 = this.panchayatsamitiList.filter(function (item) {
                return item.value == e.mba4;
            });
            if (mba4.length > 0) {
                this.panchayatsamiti = mba4[0];
            }
            this.grampanchayatname = e.mba6;
            this.constituencynumber = e.mba7;
            let mba8 = this.reservationList.filter(function (item) {
                return item.value == e.mba8;
            });
            if (mba8.length > 0) {
                this.reservationvalue = mba8[0];
            }
        },
        addGramPanchayat() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['mba2'] = this.zillaparishad.value;
            fields['mba3'] = this.zillaparishad.label;
            fields['mba4'] = this.panchayatsamiti.value;
            fields['mba5'] = this.panchayatsamiti.label;
            fields['mba6'] = this.grampanchayatname;
            fields['mba7'] = this.constituencynumber;
            fields['mba8'] = this.reservationvalue.value;
            fields['mba9'] = this.reservationvalue.label;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.creategrampanchayat(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addGramPanchayatStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getgrampanchayatlist();
                        this.zillaparishad = '';
                        this.panchayatsamiti = '';
                        this.constituencynumber = '';
                        this.reservationvalue = '';
                        this.grampanchayatname = '';
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateGramPanchayat() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['mba1'] = this.row_id;
            fields['mba2'] = this.zillaparishad.value;
            fields['mba3'] = this.zillaparishad.label;
            fields['mba4'] = this.panchayatsamiti.value;
            fields['mba5'] = this.panchayatsamiti.label;
            fields['mba6'] = this.grampanchayatname;
            fields['mba7'] = this.constituencynumber;
            fields['mba8'] = this.reservationvalue.value;
            fields['mba9'] = this.reservationvalue.label;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.creategrampanchayat(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.editGramPanchayatStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getgrampanchayatlist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getZillaparishadFilter();
            this.getPanchayatSamitiFilter();
            this.getGramPanchayatSamitiFilter();
        },
        getFilteredData( event_zillaparishad, event_panchayat, event_grampanchayat) {
            this.loading = true;
            this.zillaparishad_fks = event_zillaparishad;
            this.panchayat_fks = event_panchayat;
            this.grampanchayat_fks = event_grampanchayat;
            this.getgrampanchayatlist({
                zillaparishadfilter: this.zillaparishad_fks,
                panchayatfilter: this.panchayat_fks,
                grampanchayatfilter: this.grampanchayat_fks,
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.zillaparishad_fks = '';
            this.panchayat_fks = '';
            this.grampanchayat_fks = '';
            this.getgrampanchayatlist();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
        getZillaparishadFilter(ev) {
            this.ApiService.getzillaparishad(ev).then((items) => {
                if (items.success === true) {
                    this.zillaparishadList = items.data;
                } else {
                    this.zillaparishadList = '';
                }
            });
        },
        getPanchayatSamitiFilter(ev) {
            this.ApiService.getpanchayatsamiti(ev).then((data) => {
                if (data.status == 200) {
                    this.panchayatList = data.data;
                } else {
                    this.panchayatList = '';
                }
            });
        },
        getGramPanchayatSamitiFilter(ev) {
            this.ApiService.getgrampanchayat(ev).then((data) => {
                if (data.status == 200) {
                    this.grampanchayatList = data.data;
                } else {
                    this.grampanchayatList = '';
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
